import * as React from "react"
import styled from "styled-components"
import Layout from "@components/layout"
import Seo from "@components/seo"
import Partners from "@components/Partners"
import Goal from "@components/Goal"
import "@fontsource/open-sans-condensed"
// import Description from "@components/Description"
// import InfoBoxes from "@components/InfoBoxes"
import { size } from "../util/breakpoints"
import ScrollToTopButton from "@components/ScrollToTopButton"
import Parallax from "@components/Parallax"

const LayoutStyle = styled.div`
  flex-direction: column;
  margin: 0 auto;
  background: #071115;

  @media (max-width: ${size.laptop}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Line = styled.div`
  width: 96%;
  height: 3px;
  background: #000;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: ${size.tablet}) {
    margin: 0 auto;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
`

const IndexPage = () => (
  <Layout>
    <ScrollToTopButton />
    <Seo title="Home" />
    <LayoutStyle>
      <Parallax />
      {/* <InfoBoxes /> */}
      <Line />
      <Goal />
      <Line />
      {/* <About/> */}
      <Partners />
    </LayoutStyle>
  </Layout>
)

export default IndexPage
