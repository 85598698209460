import React from "react"
import styled from "styled-components"
import Description from "@components/Description"

const ParallaxStyle = styled.div`
  height: 60vh; /* Full viewport height */
  background-image: url(https://lirp.cdn-website.com/b78e33cd/dms3rep/multi/opt/Trilith+012-1920w.jpg);
  background-attachment: fixed; /* Makes the image fixed in place */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Ensures the image covers the section */
  border-top: 15px solid rgba(41, 172, 224, 0.8);
  border-bottom: 15px solid rgba(41, 172, 224, 0.8);
`

const ContentStyle = styled.div`
  // height: 100vh; /* Content height for scrolling */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`

export default function Parallax() {
  return (
    <div>
      <ContentStyle>
        <Description />
      </ContentStyle>
      {/* <ParallaxStyle></ParallaxStyle> */}
    </div>
  )
}
