import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

const StepAndRepeatStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  // background-color: #f8f8f8;

  .logo-container {
    padding: 0 15px;
    flex: 1 0 250px; /* Minimum width of 100px */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 100%;
    max-width: 300px;
    max-height: 160px; /* Ensures the logo fits within a consistent height */
    object-fit: contain;
    transition: transform 0.3s ease;
  }

  .logo:hover {
    transform: scale(1.15); /* Optional hover effect */
  }
`

const StudioStepAndRepeatStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 85px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 80%;
  // background-color: #f8f8f8;

  .logo-container {
    flex: 1 0 250px; /* Minimum width of 100px */
    max-width: 450px; /* Maximum width of 150px */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    max-width: 100%;
    max-height: 200px; /* Ensures the logo fits within a consistent height */
    object-fit: contain;
    transition: transform 0.3s ease;
  }

  .logo:hover {
    transform: scale(1.15); /* Optional hover effect */
  }
`

const StepAndRepeat = ({ logos, studio }) => {
  if (studio) {
    return (
      <StudioStepAndRepeatStyle>
        {logos
          .filter(m => m.image)
          .map((logo, i) => (
            <div className="logo-container" key={i}>
              <GatsbyImage
                key={Math.random()}
                className="logo"
                durationFadeIn={400}
                fadeIn
                alt={`${logo.name} Logo`}
                fluid={logo.image.childImageSharp.fluid}
              />
            </div>
          ))}
      </StudioStepAndRepeatStyle>
    )
  } else {
    return (
      <StepAndRepeatStyle>
        {logos
          .filter(m => m.image)
          .map((logo, i) => (
            <div className="logo-container" key={i}>
              <GatsbyImage
                key={Math.random()}
                className="logo"
                durationFadeIn={400}
                fadeIn
                alt={`${logo.name} Logo`}
                fluid={logo.image.childImageSharp.fluid}
              />
            </div>
          ))}
      </StepAndRepeatStyle>
    )
  }
}

export default StepAndRepeat
