import * as React from "react"
import styled from "styled-components"
import { size } from "../util/breakpoints"

const DescriptionStyle = styled.div`
  width: 100%;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 50px 100px 50px;
  background: linear-gradient(330deg, #071115 64%, #0b77a2 99%);

  @media (max-width: ${size.laptopL}) {
    padding: 1em;
  }

  @media (max-width: ${size.tablet}) {
    padding: 1em;
  }

  @media (max-width: ${size.mobileL}) {
    padding: 2em 0;
  }

  img {
    max-width: 90%;
    margin: 0 auto;

    @media (max-width: ${size.tablet}) {
      min-width: 90%;
    }
  }

  h1,
  h2,
  p,
  img {
    animation: fall 1s ease-out forwards;

    @keyframes fall {
      0% {
        transform: translateY(-100px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  h1 {
    display: flex;
    flex-direction: column;
    color: #eee;
    text-align: center;
    z-index: 999;
    font-size: 70px !important;
    margin: 40px auto;
    width: fit-content;

    @media (max-width: ${size.laptopL}) {
      font-size: 2.4em !important;
    }

    @media (max-width: ${size.tablet}) {
      font-size: 2em !important;
    }
  }

  h2 {
    color: #fff;
    text-align: center;
    line-height: 1.75em;
    z-index: 999;
    font-size: 40px !important;
    font-weight: lighter !important;
    max-width: 75%;
    margin: 10px auto;
    margin-top: 0;

    @media (max-width: ${size.laptopL}) {
      font-size: 30px !important;
    }

    @media (max-width: ${size.tablet}) {
      font-size: 30px !important;
    }

    @media (max-width: ${size.mobileL}) {
      font-size: 1.3em !important;
    }
  }

  img {
    max-width: 700px;

    @media (max-width: ${size.laptop}) {
      max-width: 500px;
    }

    @media (max-width: ${size.tablet}) {
      max-width: 380px;
    }

    @media (max-width: ${size.mobileL}) {
      max-width: 300px;
    }
  }
`

export default function Description() {
  return (
    <DescriptionStyle>
      <div>
        <img src="/logo-white.png" />
        <h1>Reel Crew Gifts</h1>
      </div>
      <h2>
        Whether it's for a dozen or a thousand, we are your{" "}
        <strong style={{ color: "#7ad4f8", textDecoration: "underline" }}>
          quick and easy
        </strong>{" "}
        one-stop shop for custom wrap/crew gifts! We will assist you with
        finding your perfect crew gift by helping you with product selection,
        your best design options, and we’ll do the work of securing the best
        pricing – all with our turn-key order process.
      </h2>
    </DescriptionStyle>
  )
}
