import * as React from "react"
import styled from "styled-components"
import { size } from "../util/breakpoints"
import { FaQuoteLeft, FaQuoteRight, FaTrophy } from "react-icons/fa"

const PicTextStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GoalStyle = styled.div`
  width: 100%;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 20px;
  background: #071115;

  .pic {
    width: 700px;
    height: 500px;
    background: rgba(255, 255, 255, 0.05);
    margin: 50px;
    border-radius: 10px;
  }

  .quote,
  h2 {
    font-size: 100px;
    width: fit-content;
    color: #2aace0 !important;
    text-shadow: 2px 2px 0px #111;
  }

  .column {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2aace0;
  }

  @media (max-width: ${size.laptopL}) {
    padding: 3em 1em;
  }

  @media (max-width: ${size.mobileL}) {
    padding: 20px 0.15em;
  }

  h2 {
    color: #fafafa;
    padding: 20px 0;
    width: 70%;
    margin: 20px;
    font-size: 40px !important;
    text-align: center;

    @media (max-width: ${size.laptopL}) {
      width: 85%;
      margin: 1.5em 0;
    }

    @media (max-width: ${size.tablet}) {
      margin: 0.8em 0;
      font-size: 30px !important;
      margin: 5px;
    }
  }

  svg.bg {
    fill: #111;
    height: 50%;
    width: 80%;
    padding: 0;
    margin: 0px !important;
    opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default function Goal({ bg, color, title }) {
  return (
    <GoalStyle style={{ background: bg }}>
      <FaTrophy className="bg" />
      <PicTextStyle>
        <h2>
          Our goal is to provide you with premium-level crew gifts with
          high-quality custom decorations, on-time with superior customer
          service from start-to-finish. We are here to help you create memorable
          and long-lasting crew gifts through the power of customizable items no
          matter the size of your production.
        </h2>
      </PicTextStyle>
    </GoalStyle>
  )
}
